import  React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLoadingContext } from '../../../shared/LoadingContextProvider'
import { useAuthDataContext } from '../../Authentication/AuthDataContextProvider'
import { signCompliance } from '../../../service/dataCalls'
import { CompliancePage } from './CompliancePage'

export const Compliance = () => {
  const { user, updateUser } = useAuthDataContext()
  const { setLoading } = useLoadingContext()
  const history = useHistory()


  const signComplianceAgreement = async() => {
    const didSignSuccessfully = await signCompliance();
    if (didSignSuccessfully) {
      await updateUser()
      history.push('/')
    }
  }

  useEffect(() => {
    if (!user) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [user, setLoading])

  return <CompliancePage 
    {
      ...{
        user,
        signComplianceAgreement
      }
    }
  />
}