import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Button, FormControl, FormControlLabel, Grid, Paper, TextField, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Checkbox,  } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { AddAPhoto, AttachFile, CloudUpload, DeleteForever, DragIndicator } from '@material-ui/icons'
import { DropzoneArea } from 'material-ui-dropzone'
import MUIDataTable from 'mui-datatables'
import { ArgumentAxis, BarSeries, Chart, Title, ValueAxis } from '@devexpress/dx-react-chart-material-ui'
import { Animation } from '@devexpress/dx-react-chart'
import { PrimaryContainer } from '../../shared/PrimaryContainer'
import { RowForm } from '../../shared/Form'

const everythingReportColumns = [
  { name: 'firstName', label: 'First' },
  { name: 'lastName', label: 'Last' },
  { name: 'email', label: 'Email' },
  { name: 'tacosGivenAllTime', label: 'All 🌮s Given' },
  { name: 'tacosReceivedAllTime', label: 'All 🌮s Received' },
  { name: 'swagPurchasedAllTime', label: '🌮s Spent on Swag' },
  { name: 'availableBalance', label: 'Available Balance' }
]

const getPreviewIcon = (workingRosterFile) => !!workingRosterFile ? <AttachFile /> : <CloudUpload />
const SWAG_DROP_TYPE = 'swagItem'

const useStyles = makeStyles((theme) => ({
  dropzone: {
    maxHeight: '100px',
  },
  dropzoneParagraph: {
    fontSize: '14px',
    textTransform: 'uppercase',
    margin: theme.spacing(2)
  },
  table: {
    color: theme.palette.primary.dark
  },
  sentenceForm: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  inlineInput: {
    maxWidth: theme.spacing(8)
  },
  form: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(2)
    }
  },
  formChild: {
    color: theme.palette.primary.dark,
    minWidth: '250px',
    width: '80%'
  },
  imageDropzone: {
    maxHeight: '100px',
  },
  imageDropzoneImage: {
    height: '80px',
    margin: '0 auto'
  },
  imageDropzoneParagraph: {
    fontSize: '14px',
    textTransform: 'uppercase',
    margin: theme.spacing(2),
    '& > svg': {
      display: 'none'
    }
  },
  swagImage: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.14)',
    height: '50px',
    margin: theme.spacing(1)
  },
  breathingRoom: {
    margin: theme.spacing(1)
  },
  imageDroppable: {
    width: '80%'
  },
  backgroundColor: 'rgba(255, 255, 255, 0.2)'
}))


const SwagImagesDroppable = ({ itemIndex, item, classes }) => {
  const getImageItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    height: '50px',
    margin: '0 10px',
    boxShadow: isDragging ? 
      '0 3px 6px 0 rgba(0, 0, 0, 0.20), 0 4px 15px 0 rgba(0, 0, 0, 0.19)' : 
      '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.14)',
      ...draggableStyle
  })

  const getListStyle = () => ({
    padding: '8px',
    width: '100%',
    display: 'flex',
    overflow: 'auto'
  })

  return (
    <div className={classes.imageDroppable}>
      <Droppable droppableId={`item${item.displayOrder}-imgs`} type={itemIndex} direction='horizontal'>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle()}
          >
            {item.images.map((img, i) => {
              return (
                  <Draggable key={`item${itemIndex}-img${i}`} draggableId={`item${itemIndex}-img${i}`} index={i}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} style={getImageItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                      <Grid item {...provided.dragHandleProps}>
                        <DragIndicator /> 
                        <img style={{ height: '50px' }} src={img} alt={item.title}/>
                      </Grid>           
                    </div>     
                  )}
                  </Draggable>
              )
            })}
          {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>  
  )
}

export const AdminPage = (
  {
    workingTacoBudgetCounts, handleWorkingTacoBudgetChange, handleWorkingTacoBudgetUpdate,
    workingRosterFile, setWorkingRosterFile, downloadCurrentRoster, uploadNewRosterAndProcess,
    everythingReportData, loadEverythingReport,
    usageReportData, loadUsageReport,
    lastReportRan,
    swagOrders, cancelSwagOrderAndReload, fulfillSwagOrderAndReload,
    workingAwardTaco, handleAwardTacoChange, handleAwardTaco, workingAwardTacoUserSelection, setWorkingAwardTacoUserSelection,
    availableUsersWithId,
    workingSwagItemEntry, handleWorkingSwagItemEntryUpdate, handleSwagImageUpload, handleAddNewSwagItem, workingSwagItemImage, workingSwagItemImagesView,
    swagItems, updateSwagItemWithNewImage, updateSwagItemWithNewFieldValue, removeSwagItem,
    tempSwagState, setTempSwagState, handleUpdateSwagItemList
  }) => {
  const classes = useStyles()
  const history = useHistory()
  const workingSwagItems = useMemo(() => tempSwagState.length ?  tempSwagState.filter(x => x.available) : swagItems.filter(x => x.available), [tempSwagState, swagItems])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) return // dropped outside the list
    if (result.type === SWAG_DROP_TYPE) {
      const items = reorder(workingSwagItems, result.source.index, result.destination.index)
      setTempSwagState(items)
    } else {
      const itemImages = reorder(workingSwagItems[result.type].images, result.source.index, result.destination.index)
      const cpWorkingSwagItems = [...workingSwagItems]
      cpWorkingSwagItems[result.type].images = itemImages
      setTempSwagState(cpWorkingSwagItems)
    }
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: '4px',
    margin: `0 0 2px 0`,
    background: isDragging ? '#DBAF00' : '#8E96D8',
      ...draggableStyle
  })

  const getListStyle = isDraggingOver => ({
    padding: '2px',
    width: '100%'
  })

  return (
    <PrimaryContainer mainTitle='Admin'>
      <RowForm>
        <Button onClick={downloadCurrentRoster} color='secondary' variant='contained'>Download current roster</Button>
        <Grid container direction='row' alignItems='center' justify='space-between'>
          <Grid item xs={6}>
            <DropzoneArea
              dropzoneClass={classes.dropzone}
              dropzoneParagraphClass={classes.dropzoneParagraph}
              showPreviewsInDropzone={false}
              Icon={() => getPreviewIcon(workingRosterFile)}
              filesLimit={1}
              onChange={(files) => { if (files.length) setWorkingRosterFile(files[0]) }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => uploadNewRosterAndProcess(workingRosterFile, setWorkingRosterFile)} disabled={!workingRosterFile} color='secondary' variant='contained'>Upload updated roster</Button>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Button type='submit' onClick={() => history.push('/admin/user-mgmt')} color='secondary' variant='contained'>Manage Users</Button>
        </Grid>
      </RowForm>
      <RowForm>
        <TextField defaultValue={0} value={workingTacoBudgetCounts.partnerBudget} onChange={handleWorkingTacoBudgetChange} label='Partner monthly taco budget:' id='partnerBudget' />
        <TextField defaultValue={0} value={workingTacoBudgetCounts.engineeringLeadBudget} onChange={handleWorkingTacoBudgetChange} label='Engineering Lead monthly taco budget:' id='engineeringLeadBudget'/>
        <TextField defaultValue={0} value={workingTacoBudgetCounts.teamLeadBudget} onChange={handleWorkingTacoBudgetChange} label='Team Lead monthly taco budget:' id='teamLeadBudget'/>
        <TextField defaultValue={0} value={workingTacoBudgetCounts.employeeBudget} onChange={handleWorkingTacoBudgetChange} label='Employee monthly taco budget:' id='employeeBudget'/>
        <Button type='submit' onClick={handleWorkingTacoBudgetUpdate} color='secondary' variant='contained'>Allocate Monthly Tacos</Button>
      </RowForm>
      <FormControl color='secondary' fullWidth>
        <Grid container direction='row'>
        <Grid className={classes.form} item xs={6} container direction='column'>
          <Grid item>
            <Button onClick={loadEverythingReport} color='secondary' variant='contained'>Run everything report</Button>
          </Grid>
        </Grid>
        <Grid className={classes.form} item xs={6} container direction='column'>
          <Grid item>
            <Button onClick={loadUsageReport} color='secondary' variant='contained'>Run usage report</Button>
          </Grid>
        </Grid>
        </Grid>
        <Grid direction='row'>
        {!!(everythingReportData.length && (lastReportRan === 'everything')) && (
            <Grid item>
              <MUIDataTable
                title='Everything Report'
                columns={everythingReportColumns}
                className={classes.table}
                data={everythingReportData}
                options={{
                  selectableRows: 'none',
                  fixedSelectColumn: false
                }}
              />
            </Grid>
          )}
          {!!(usageReportData.length && (lastReportRan === 'usage')) && (
            <Grid item>
              <Paper>
                <Chart data={usageReportData} >
                  <ArgumentAxis />
                  <ValueAxis max={100} />
                  <BarSeries
                    valueField='tacosGiven'
                    argumentField='month'
                  />
                  <Title text='% Tacos Given Per Month' />
                  <Animation />
                </Chart>
              </Paper>
            </Grid>
          )}
        </Grid>     
      </FormControl>
      <FormControl onSubmit={handleAwardTaco} color='secondary' fullWidth>
      <Grid container direction='row' className={classes.form}>
        <Grid item xs={3}>
          <Autocomplete 
            id='recipientEmail'
            options={availableUsersWithId}
            getOptionLabel={(option) => option.name}
            onChange={(_, newInputValue) => {
              if (newInputValue != null) {
                setWorkingAwardTacoUserSelection(newInputValue)
                handleAwardTacoChange('recipientEmail', newInputValue, 'email')
              }
            }}
            className={classes.formChild}
            fullWidth
            renderInput={(params) => <TextField {...params} value={workingAwardTacoUserSelection} label='Who gets awarded taco(s)' variant='outlined' />}
          />
        </Grid>
        <Grid item xs={6} className={classes.formChild}><TextField fullWidth label='How did they earn it?' id='comment' variant='outlined'  value={workingAwardTaco.comment} onChange={(e) => handleAwardTacoChange('comment', e.target.value)} /></Grid>
        <Grid item xs={2}><Button type='submit' onClick={handleAwardTaco} color='secondary' variant='contained' disabled={!workingAwardTaco.recipientEmail}> Award {workingAwardTaco.tacoCount} Taco </Button></Grid>
      </Grid>
      </FormControl>
      <FormControl color='secondary' fullWidth>
        <Grid className={classes.form} container direction='row' alignItems='flex-end' justify='center'>
          <TableContainer component={Paper}>
            <Table className={classes.table} size='small' aria-label='a dense table'>
              <TableHead>
                <TableRow>
                  <TableCell align='right'>Employee</TableCell>
                  <TableCell align='right'>Swag Item</TableCell>
                  <TableCell align='right'>Size</TableCell>
                  <TableCell align='right'>Requested Date</TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {swagOrders.map((swagOrger) => (
                  <TableRow key={swagOrger.id}>
                    <TableCell align='right'>{swagOrger.user.firstName} {swagOrger.user.lastName}</TableCell>
                    <TableCell align='right'>{swagOrger.swag.title}</TableCell>
                    <TableCell align='right'>{swagOrger.sizeSelection || '--'}</TableCell>
                    <TableCell align='right'>{new Date(swagOrger.dateCreated).toLocaleDateString()}</TableCell>
                    <TableCell align='right'>
                      <Button onClick={() => cancelSwagOrderAndReload(swagOrger.id)} color='error' variant='contained'>Cancel</Button>
                    </TableCell>
                    <TableCell align='right'>
                      <Button onClick={() => fulfillSwagOrderAndReload(swagOrger.id)} color='secondary' variant='contained'>Mark Fulfilled</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </FormControl>
      <FormControl color='secondary' fullWidth>
        <Grid className={classes.form} container direction='row' alignItems='center' justify='space-around'>
          <Grid item xs={2}>
            <TextField value={workingSwagItemEntry.title} onChange={handleWorkingSwagItemEntryUpdate} label='Swag Name:' id='title' variant='outlined' fullWidth/>
          </Grid>
          <Grid item xs={2}>
            <TextField type='number' value={workingSwagItemEntry.cost} onChange={handleWorkingSwagItemEntryUpdate} label={`Swag Cost (in taco's):`} id='cost' variant='outlined' fullWidth/>
          </Grid>
          <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox checked={workingSwagItemEntry.hasSizeOptions} onChange={(e) => handleWorkingSwagItemEntryUpdate(e, true)} id='hasSizeOptions'/>
            }
            label='Size options'
          />
          </Grid>
          <Grid item xs={4}>
          {(workingSwagItemImage && workingSwagItemImagesView.length) ? 
            (
              workingSwagItemImagesView.map((img, i) => (
                <img className={classes.imageDropzoneImage} src={img} alt={'Uploaded swag' + (i + 1)} />
              ))
            ) :
            <DropzoneArea
              Icon={() => <CloudUpload />}
              dropzoneText='Upload swag image'
              dropzoneClass={classes.imageDropzone}
              showPreviewsInDropzone={false}
              dropzoneParagraphClass={classes.imageDropzoneParagraph}
              filesLimit={3}
              onChange={(files) => { if (files.length) handleSwagImageUpload(files) }}
            />
          }
          </Grid>
          <Grid item xs={1}>
            <Button disabled={!workingSwagItemEntry.title || !workingSwagItemImage || !(workingSwagItemEntry.cost >= 0)} onClick={handleAddNewSwagItem} color='secondary' variant='contained'>Add Swag</Button>
          </Grid>
          <Grid item xs={10}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable' type={SWAG_DROP_TYPE}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {workingSwagItems.map((item, index) => (
                      <Draggable key={`item${item.displayOrder}`} draggableId={`item${item.displayOrder}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <Grid container direction='row' justify='space-between' alignItems='center'>
                              <Grid item xs={1} {...provided.dragHandleProps}> <DragIndicator /> </Grid>
                              <Grid item xs={2}>
                                <TextField size='small' value={item.title} onChange={(e) => updateSwagItemWithNewFieldValue(e.target.value, item, 'title')} label='Name:' id={`${item.displayOrder}.title`}/>
                              </Grid>
                              <Grid item xs={2}>
                                <TextField size='small' type='number' value={item.cost} onChange={(e) => updateSwagItemWithNewFieldValue(e.target.value, item, 'cost')} label='Cost:' id={`${item.displayOrder}.cost`}/>
                              </Grid>
                              <Grid item xs={1}>
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={item.hasSizeOptions} onChange={(e) => updateSwagItemWithNewFieldValue(e.target.checked, item, 'hasSizeOptions')} id={`${item.displayOrder}.hasSizeOptions`} />
                                  }
                                  label='Size:'
                                  labelPlacement='top'
                                />
                              </Grid>
                              <Grid item xs={4} container direction='row' alignItems='center'>
                                <label htmlFor={`item${item.displayOrder}-img-add`}>
                                  <input id={`item${item.displayOrder}-img-add`} type='file' style={{ display: 'none'}} onChange={(e) => updateSwagItemWithNewImage(e, item)} />
                                  <AddAPhoto className={classes.breathingRoom}/>
                                </label>
                                {(item && item.images && item.images.length > 1) ? (
                                  <SwagImagesDroppable {...{ classes, item, itemIndex: index }} />
                                ) : (
                                  <img className={classes.swagImage} src={item.images[0]} alt={item.title}/>
                                )}
                              </Grid>
                              <Grid item xs={1} container direction='row' justify='space-evenly'>
                                <DeleteForever onClick={() => removeSwagItem(item)}/>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
          <Grid item xs={1}>
            <Button type='submit' disabled={!tempSwagState.length} onClick={handleUpdateSwagItemList} color='secondary' variant='contained'>Save Swag Changes</Button>
          </Grid>
        </Grid>
      </FormControl>
      <FormControl>
        <Button type='submit' onClick={() => history.push('/office')} color='secondary' variant='contained'>Switch to Office View Mode</Button>
      </FormControl>
      <FormControl>
      </FormControl>
    </PrimaryContainer>
  )
}