import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { downloadCurrentRoster, uploadNewRoster, uploadNewSwagItem, getImageByPath } from '../../service/storageCalls'
import { useLoadingContext } from '../../shared/LoadingContextProvider'
import { useSwagItems } from '../../shared/useSwagItems'
import { awardTaco, addNewSwagItem, getAdminListOfUsers, getEverythingReportData, getTacoBudgetCounts, getUsageReportData, getUserBalance, postSwagTransaction, getOpenSwagOrders, fulfillSwagOrder, cancelSwagOrder, runMonthlySeedFunction, updateSwagItemList, uploadAndProcessUserData } from '../../service/dataCalls'
import { taco_defaultTransaction } from '../../data/models'
import { useAuthDataContext } from '../Authentication/AuthDataContextProvider'
import { AdminPage } from './AdminPage'

const IMAGE_JOINER = ':&'

const mapUsageReportInfo = (useageReportRawData) => useageReportRawData.map(monthData => ({
  month: moment(monthData.yearMonth, 'YYYYMM').format('MMM \'YY'),
  tacosGiven: (monthData.totalTacosGiven / monthData.totalTacosBudget) * 100
}))

export const Admin = () => {
  const [workingTacoBudgetCounts, setWorkingTacoBudgetCounts] = useState({})
  const [workingRosterFile, setWorkingRosterFile] = useState(null)
  const [everythingReportData, setEverythingReportData] = useState([])
  const [usageReportData, setUsageReportData] = useState([])
  const [lastReportRan, setLastReportRan] = useState(null)
  const [userTypeaheadSelection, setUserTypeaheadSelection] = useState({})
  const [selectedUserBalance, setSelectedUserBalance] = useState(null)
  const [userSelected, setUserSelected] = useState(false)
  const [swagTransactionCost, setSwagTransactionCost] = useState(null)
  const [swagOrders, setSwagOrders] = useState([])
  const [workingSwagItemEntry, setWorkingSwagItemEntry] = useState({})
  const [workingSwagItemImage, setWorkingSwagItemImage] = useState(null)
  const [workingSwagItemImagesView, setWorkingSwagItemImagesView] = useState([])
  const [availableUsersWithId, setAvailableUsersWithId] = useState(null)
  const [ tempSwagState, setTempSwagState ] = useState([])
  
  const { swagItems, loadSwagItems } = useSwagItems()
  const [workingAwardTaco, setWorkingAwardTaco] = useState(taco_defaultTransaction)
  const [workingAwardTacoUserSelection, setWorkingAwardTacoUserSelection] = useState(taco_defaultTransaction)
  const { user } = useAuthDataContext()
  
  const { setLoading } = useLoadingContext()


  const loadTacoBudgetCounts = useCallback(async() => {
    const tacoBudgetCounts = await getTacoBudgetCounts()
    setWorkingTacoBudgetCounts(tacoBudgetCounts.tacoBudgetConfiguration)
  }, [setWorkingTacoBudgetCounts])


  const setAdminUserListData = useCallback(async() => {
    const usersWithAdminData = await getAdminListOfUsers()
    setAvailableUsersWithId(usersWithAdminData)
  }, [])

  const setOpenSwagOrders = useCallback(async() => {
    const swagOrders = await getOpenSwagOrders()
    setSwagOrders(swagOrders)
  }, [])

  const cancelSwagOrderAndReload = useCallback(async(orderId) => {
    await cancelSwagOrder(orderId)
    setOpenSwagOrders()
  }, [setOpenSwagOrders])

  const fulfillSwagOrderAndReload = useCallback(async(orderId) => {
    await fulfillSwagOrder(orderId)
    setOpenSwagOrders()
  }, [setOpenSwagOrders])

  useEffect(() => {
    setAdminUserListData()
    setOpenSwagOrders()
    loadTacoBudgetCounts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!availableUsersWithId) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [availableUsersWithId, setLoading])


  const handleWorkingTacoBudgetChange = (e) => { setWorkingTacoBudgetCounts({ ...workingTacoBudgetCounts, [e.target.id]: e.target.value }) }

  const uploadNewRosterAndProcess = async (file, setWorkingFile) => {
    try {
      const successfullyProcessedData = await uploadAndProcessUserData(file)
      if (successfullyProcessedData) await uploadNewRoster(file, setWorkingFile)
    } catch (err) {
      console.error(err)
    }
  }

  const loadEverythingReport = async () => {
    const reportData = await getEverythingReportData()
    setEverythingReportData(reportData.everythingReportResponse)
    setLastReportRan('everything')
  }

  const loadUsageReport = async () => {
    const reportData = await getUsageReportData()
    setUsageReportData(mapUsageReportInfo(reportData.usageReportResponse))
    setLastReportRan('usage')
  }

  const handleAwardTacoChange = (id, inputValue, key) => { 
    setWorkingAwardTaco({ ...workingAwardTaco, [id]: key ? inputValue[key] : inputValue }) 
  }

  const handleAwardTaco = async(e) => {
    e.preventDefault()
    await awardTaco(user.email, workingAwardTaco)
    setWorkingAwardTaco(taco_defaultTransaction)
  }

  const handleUserSelect = async (_, newInputValue) => {
    if (newInputValue != null) {
      setUserTypeaheadSelection(newInputValue)
      const selectedUserBalance = await getUserBalance(newInputValue.id)
      setSelectedUserBalance(selectedUserBalance.tacoBalance)
      setUserSelected(true)
    }
  }

  const handleSwagTransaction = async () => {
    await postSwagTransaction(userTypeaheadSelection.id, Number(swagTransactionCost))
    setUserTypeaheadSelection({})
    setSelectedUserBalance(null)
    setSwagTransactionCost(null)
    setUserSelected(false)
  }

  const handleWorkingTacoBudgetUpdate = async () => {
    await runMonthlySeedFunction(workingTacoBudgetCounts)
    loadTacoBudgetCounts()
  }

  const handleWorkingSwagItemEntryUpdate = (e, useChecked) => { setWorkingSwagItemEntry({ ...workingSwagItemEntry, [e.target.id]: useChecked ? e.target.checked : e.target.value }) }

  const handleSwagImageUpload = async (files) => {
    let swagImagesUrl = ''
    const imagesToRender = []
    await Promise.all(files.map(async(file) => {
      const newSwagImageUrl = await uploadNewSwagItem(file)
      swagImagesUrl += (newSwagImageUrl + IMAGE_JOINER)
      const imageUrlWithToken = await getImageByPath(newSwagImageUrl)
      imagesToRender.push(imageUrlWithToken)
    }))
    setWorkingSwagItemImagesView(imagesToRender)
    setWorkingSwagItemImage(swagImagesUrl)
  }

  const handleAddNewSwagItem = async () => {
    try{
      const addedSuccessfully = await addNewSwagItem({...workingSwagItemEntry, imageUrl: workingSwagItemImage })
      if (addedSuccessfully.data) {
        setWorkingSwagItemEntry({ title: '', cost: 0 })
        setWorkingSwagItemImage(null)
        setWorkingSwagItemImagesView([])
        loadSwagItems()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const updateSwagItemWithNewImage = async (e, swagItem) => {
    const file = e.target.files[0]
    const workFromSwagList = tempSwagState.length ? [...tempSwagState] : [...swagItems]
    const workFromIndex = workFromSwagList.findIndex(x => x.displayOrder === swagItem.displayOrder)
    try {
      const newSwagImageUrl = await uploadNewSwagItem(file)
      swagItem.imageUrl += (IMAGE_JOINER + newSwagImageUrl + IMAGE_JOINER)
      const imageUrlWithToken = await getImageByPath(newSwagImageUrl)
      swagItem.images.push(imageUrlWithToken)
      workFromSwagList[workFromIndex] = swagItem
      setTempSwagState(workFromSwagList)
    } catch (err) {
      console.error(err)
    }
  }

  const updateSwagItemWithNewFieldValue = (newVal, swagItem, fieldName) => {
    const workFromSwagList = tempSwagState.length ? [...tempSwagState] : [...swagItems]
    const workFromIndex = workFromSwagList.findIndex(x => x.displayOrder === swagItem.displayOrder)
    workFromSwagList[workFromIndex] = { ...swagItem, [fieldName]: newVal }
    setTempSwagState(workFromSwagList)
  }

  const removeSwagItem = (swagItem) => {
    updateSwagItemWithNewFieldValue(false, swagItem, 'available')
  }

  const handleUpdateSwagItemList = async() => {
    const mappedSwag = []
    tempSwagState.forEach((swagItem, i) => {
      const mappedSwagItem = ({ ...swagItem, displayOrder: i })
      mappedSwagItem.imageUrl = mappedSwagItem.images.map(img => `${img}${IMAGE_JOINER}`).join('')
      delete mappedSwagItem.images
      mappedSwag.push(mappedSwagItem)
    })

    try {
       await updateSwagItemList(mappedSwag)
       await loadSwagItems()
       setTempSwagState([])
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <AdminPage 
      {...{
        workingTacoBudgetCounts, handleWorkingTacoBudgetChange, handleWorkingTacoBudgetUpdate,
        workingRosterFile, setWorkingRosterFile, downloadCurrentRoster, uploadNewRosterAndProcess,
        everythingReportData, loadEverythingReport,
        usageReportData, loadUsageReport,
        lastReportRan,
        swagOrders, cancelSwagOrderAndReload, fulfillSwagOrderAndReload,
        workingAwardTaco, handleAwardTacoChange, handleAwardTaco, workingAwardTacoUserSelection, setWorkingAwardTacoUserSelection,
        userTypeaheadSelection, availableUsersWithId, handleUserSelect, selectedUserBalance, userSelected,
        workingSwagItemEntry, handleWorkingSwagItemEntryUpdate, handleSwagImageUpload, handleAddNewSwagItem, workingSwagItemImage, workingSwagItemImagesView,
        swagTransactionCost, setSwagTransactionCost, handleSwagTransaction, swagItems, updateSwagItemWithNewImage, updateSwagItemWithNewFieldValue, removeSwagItem,
        tempSwagState, setTempSwagState, handleUpdateSwagItemList
      }}
    />
  )
}