import  React, { createContext, useContext, useMemo, useState } from 'react'

export const LoadingContext = createContext({})

export const LoadingProvider = props => {
  const [loading, setLoading] = useState(false)
  const [loadingCount, setLoadingCount] = useState(0)
  const [resolvedCount, setResolvedCount] = useState(0)

  const setLoadingStarted = () => {
    setLoading(true)
    setLoadingCount(loadingCount + 1)
  }
  const setLoadingComplete = () => {
    if (loadingCount <= (resolvedCount + 1)) { setLoading(false) }
    setResolvedCount(resolvedCount + 1)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadingValue = useMemo(() => ({ loading, setLoadingStarted, setLoadingComplete, setLoading }), [loading])
  return <LoadingContext.Provider value={loadingValue} {...props} />
}

export const useLoadingContext = () => useContext(LoadingContext)