import  { useCallback, useEffect, useState } from 'react'
import { getSwagStoreItems } from '../service/dataCalls'
import { getImageByPath } from '../service/storageCalls'

export const IMAGE_JOINER = ':&'

export const useSwagItems = () => {
  const [swagItems, setSwagItems] = useState([])
  
  const loadSwagItems = useCallback(async() => {
    const swagStoreItems = await getSwagStoreItems()
    const availableSwag = await Promise.all(swagStoreItems.availableSwag.map(async(swagItem) => {
      const images = swagItem.imageUrl.split(IMAGE_JOINER)
      const viewableImages = await Promise.all(images.filter(Boolean).map(async(img) => await getImageByPath(img)))
      return ({ ...swagItem, images: viewableImages })
    }))
    availableSwag.sort((a, b) => a.displayOrder - b.displayOrder)
    setSwagItems(availableSwag)
  }, [])

  useEffect(() => { loadSwagItems() }, [loadSwagItems])

  return ({ swagItems, loadSwagItems })
}