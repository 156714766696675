import React from 'react'
import { Grid, Typography, Paper, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = (hasSubtitle) => makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.primary.dark,
    height: '100%',
  },
  mainTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '2rem',
    paddingTop: theme.spacing(3),
    paddingBottom: hasSubtitle ? 0 : theme.spacing(2),
  },
  mainSubtitle: {
    textAlign: 'center',
    fontSize: '1rem',
    paddingBottom: theme.spacing(2)
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '94%',
    margin: '0 auto',
    height: `calc(94% - (${theme.spacing(3)}px + 4rem + ${hasSubtitle ? 0 : theme.spacing(2)}px))`,
    overflow: 'auto'
  }
}))


export const PrimaryContainer = ({ mainTitle, mainSubtitle, shortTitle, children }) => {
  const classes = useStyles(!!mainSubtitle)()
  return (
    <Paper className={classes.main}>
      {!!shortTitle ? (<>
        <Hidden mdDown>
          <Typography Typography className={classes.mainTitle}>{mainTitle}</Typography>
        </Hidden>
        <Hidden lgUp>
          <Typography Typography className={classes.mainTitle}>{shortTitle}</Typography>
        </Hidden>
      </>) : (<Typography className={classes.mainTitle}>{mainTitle}</Typography>)}
      {!!classes.mainSubtitle && <Typography className={classes.mainSubtitle}>{mainSubtitle}</Typography>}
      <Grid className={classes.mainContent} container direction='column' wrap='nowrap' alignItems='center'>
        {children}
      </Grid>
    </Paper>
  )
}