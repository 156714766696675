import  React from 'react'
import { CssBaseline, Grid, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    width: '100%',
    padding: '15%',
  },
  image: {
    maxWidth: '100%'
  }
}))


const termsAndAgreementMessage = `I agree to use SpaceTacos (“the software”) as intended for peer-to-peer recognition and will not abuse its functionality. I authorize others to leave comments and kudos for me, and I agree to do the same fairly and uniformly. I will be fair and accurate in the information I provide using the software. I will use the software in no way that exhibits self-interest or for personal gain. I understand and agree that I will not contribute malicious, defaming, false, misleading, or otherwise inaccurate information. I understand and agree that I will not use the Company-provided software in ways that violate any Company policy, stance, or employee handbook. Should I violate any terms herein, I understand this is grounds for disciplinary action.`

export const CompliancePage = ({ user, signComplianceAgreement }) => {
  const classes = useStyles()

  return (
    <>
      <CssBaseline />
        <Grid container alignContent='center' direction='row' className={classes.container}>
          <Hidden mdDown>
            <Grid item md={3}>
              <img className={classes.image} src={'https://firebasestorage.googleapis.com/v0/b/forty-spacetacos.appspot.com/o/welcome.svg?alt=media&token=57d027f0-90d4-4569-8375-184384826c3b'} alt=""/>
            </Grid>
          </Hidden>
          <Grid container item md={9} alignItems='center' spacing={5}>
            <Grid item row>
              <Typography align='center' variant='h5'>{`'SpaceTacos' Terms & Agreement`}</Typography>
            </Grid>
            <Grid item row>
              <Typography color='secondary'>{termsAndAgreementMessage}</Typography>
            </Grid>
            <Grid item row>
              <Button onClick={signComplianceAgreement} color='secondary' variant='contained'>{`I, ${user.firstName} ${user.lastName}, agree.`}</Button>        
            </Grid>
          </Grid>
        </Grid>
    </>
  )
}