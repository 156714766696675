import  React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { PrimaryContainer } from '../../../shared/PrimaryContainer'
import { InlineTaco } from '../../../shared/InlineTaco'

export const MainRemaining = ({ availableUsers }) => (
  <PrimaryContainer mainTitle={`Remaining Tacos`} shortTitle={`Remaining`}>
    {availableUsers && availableUsers
      .map(tacoHolder => (
      <Grid container direction='row' key={tacoHolder.email}>
        <Grid item xs={6}><Typography>{tacoHolder.name}</Typography></Grid>
        <Grid item xs={6}><Typography>still has <InlineTaco />(s) left to give</Typography></Grid>
      </Grid>
    ))}
  </PrimaryContainer>
)
