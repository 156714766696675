import * as go from 'axios'
import fileDownload from 'js-file-download'
import firebase from 'firebase/app'
import 'firebase/storage'

const FILE_NAME = 'Employee_Roster.xlsx'

const SWAG_DIRECTORY = 'swagimages/'

export const downloadCurrentRoster = async() => {
  var storage = firebase.storage()
  var pathReference = storage.ref(FILE_NAME)
  const downloadUrl = await pathReference.getDownloadURL()
  const data = await go.get(downloadUrl, { responseType: 'blob',  timeout: 30000, withCredentials: false, mode: 'no-cors' })
  fileDownload(data.data, FILE_NAME)
}

export const uploadNewRoster = async(file, setWorkingFile) => {
  var storage = firebase.storage()
  var pathReference = storage.ref(FILE_NAME)
  await pathReference.put(file)
  setWorkingFile(null)
}

export const uploadNewSwagItem = async(file) => {
  var storage = firebase.storage()
  var pathReference = storage.ref(`${SWAG_DIRECTORY}/${file.name}`)
  await pathReference.put(file)
  return pathReference.toString()
}

export const getImageByPath = async(imagePath) => {
  var storage = firebase.storage()
  var pathReference = storage.refFromURL(imagePath)
  return await pathReference.getDownloadURL()
}