import  React, { useCallback, useEffect, useState } from 'react'
import he from 'he'
import { getAllUsersWithTacosToGive, getAllUsersWithReceivedTacos, getTopTacoChamp, getTypeaheadList, getUserEverything, sendTaco } from '../../service/dataCalls'
import { taco_defaultTransaction } from '../../data/models'
import { useLoadingContext } from '../../shared/LoadingContextProvider'
import { useAuthDataContext } from '../Authentication/AuthDataContextProvider'
import { MainPage } from './MainPage'

export const Main = ({ isPublicView }) => {
  const [workingTaco, setWorkingTaco] = useState(taco_defaultTransaction)
  const [tacoTypeaheadUsers, setTacoTypeaheadUsers] = useState(null)
  const [tacoTypeaheadSelection, setTacoTypeaheadSelection] = useState({})
  const [lastMonthTopTacoChamp, setLastMonthTopTacoChamp] = useState(null)
  const [fullUserData, setFullUserData] = useState(null)
  const [usersWithTacosToGive, setUsersWithTacosToGive] = useState(null)
  const [usersWithTacosReceived, setUsersWithTacosReceived] = useState(null)

  const { user, token } = useAuthDataContext()
  const { setLoading } = useLoadingContext()

  const setUsersWithTacosToGiveData = useCallback(async() => {
    const usersWithTacosToGiveData = await getAllUsersWithTacosToGive()
    const usersWithTacosToGiveWithNames = usersWithTacosToGiveData.map((user) => ({ ...user, name: `${user.firstName} ${user.lastName}`}))
    setUsersWithTacosToGive(usersWithTacosToGiveWithNames)
  }, [])

  const setUsersWithReceivedTacosData = useCallback(async() => {
    const usersWithTacosReceivedData = await getAllUsersWithReceivedTacos()
    const usersWithTacosReceivedWithFormattedComments = usersWithTacosReceivedData.map((u) => ({ ...u, commentsReceived: u.commentsReceived.filter(c => !!c.comment).map(c => he.decode(c.comment))}))
    setUsersWithTacosReceived(usersWithTacosReceivedWithFormattedComments)
  }, [])

  const setTypeaheadListData = useCallback(async() => {
    const employeesForTypeAhead = await getTypeaheadList()
    setTacoTypeaheadUsers(employeesForTypeAhead)
  }, [])

  const setUserData = useCallback(async() => {
    if (token) {
      const userEverythingDataResults = await getUserEverything(token)
      setFullUserData(userEverythingDataResults)
    }
  }, [token])

  const setLastMonthChampData = useCallback(async() => {
    const lastMonthTacoChampResponse = await getTopTacoChamp()
    setLastMonthTopTacoChamp(lastMonthTacoChampResponse.topTacoChamp)
  }, [])

  useEffect(() => {
    if (!usersWithTacosToGive) { setUsersWithTacosToGiveData() }
    if (!usersWithTacosReceived) { setUsersWithReceivedTacosData() }
    if (!tacoTypeaheadUsers) { setTypeaheadListData() }
    if (!fullUserData) { setUserData() }
    if (!lastMonthTopTacoChamp) { setLastMonthChampData() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!usersWithTacosToGive || !usersWithTacosReceived || !tacoTypeaheadUsers || !fullUserData) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [usersWithTacosToGive, usersWithTacosReceived, tacoTypeaheadUsers, fullUserData, setLoading])

  const handleChange = (id, inputValue, key) => { 
    setWorkingTaco({ ...workingTaco, [id]: key ? inputValue[key] : inputValue }) 
  }

  const handleSendTaco = async(e) => {
    e.preventDefault()
    await sendTaco(user.email, workingTaco)
    setWorkingTaco(taco_defaultTransaction)
    setUsersWithReceivedTacosData()
    setUserData()
  }

  return <MainPage 
    {
      ...{
        tacoTypeaheadSelection,
        handleChange,
        user,
        fullUserData,
        isPublicView,
        usersWithTacosToGive,
        usersWithTacosReceived,
        handleSendTaco,
        workingTaco,
        tacoTypeaheadUsers,
        setTacoTypeaheadSelection,
        lastMonthTopTacoChamp
      }
    }
  />
}