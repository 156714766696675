import React from 'react'
import { FormControl, Grid} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  form: {
   backgroundColor: theme.palette.primary.contrastText,
   color: theme.palette.primary.dark,
   marginTop: theme.spacing(3),
   marginBottom: theme.spacing(3),
 },
 formChild: {
   margin: theme.spacing(3)
 },
}))

export const RowForm = ({ children }) => {
  const classes = useStyles()
  return (
    <FormControl color='secondary' fullWidth>
        <Grid className={classes.form} container direction='row' justify='space-around' alignItems='center'>
          {
            children.map(child => (
              <Grid className={classes.formChild} item>
                {child}
              </Grid>
            ))
          }
        </Grid>
      </FormControl>
  )
}