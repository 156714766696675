export const admin_tacoBudgetCounts = { 
  partnerBudget: 10, 
  employeeBudget: 5,
  teamLeadBudget: 7
}

export const admin_everythingReport = [
  {
    firstName: 'Callan',
    lastName: 'Morrison',
    email: 'coolest@cat.com',
    tacosReceivedAllTime: 2,
    remainingTacoBudget: 2
  },
  {
    firstName: 'Matt',
    lastName: 'Vieten',
    email: 'meh@cat.com',
    tacosReceivedAllTime: 6,
    remainingTacoBudget: 1
  }
]

export const admin_usageReport = [
  {
    monthDate: new Date(),
    totalTacoBudgeted: 30,
    totalTacoGiven: 3
  },
  {
    monthDate: new Date(),
    totalTacoBudgeted: 90,
    totalTacoGiven: 85
  }
]

export const admin_userBalance = {
  remainingBalance: 10
}

export const taco_defaultTransaction = { 
  ownerEmail: null, 
  recipientEmail: null, 
  tacoCount: 1,
  comment: ''
}

export const you_swagStoreItems = [
  {
    id: 0,
    title: 'Cool Hat',
    cost: 6,
    imageUrl: 'https://cdn.shopify.com/s/files/1/0242/5431/files/mainbrim_grande.jpg?256'
  },
  {
    id: 1,
    title: 'Meta Taco',
    cost: 8,
    imageUrl: 'https://www.fifteenspatulas.com/wp-content/uploads/2019/05/Ground-Beef-Tacos-Fifteen-Spatulas-11.jpg'
  },
  {
    id: 2,
    title: 'Rick A.',
    cost: 400,
    imageUrl: 'https://img.apmcdn.org/768cb350c59023919f564341090e3eea4970388c/normal/2aa134-20180309-rick-astley.jpg'
  },
  {
    id: 0,
    title: 'Cool Hat',
    cost: 6,
    imageUrl: 'https://cdn.shopify.com/s/files/1/0242/5431/files/mainbrim_grande.jpg?256'
  },
  {
    id: 1,
    title: 'Meta Taco',
    cost: 8,
    imageUrl: 'https://www.fifteenspatulas.com/wp-content/uploads/2019/05/Ground-Beef-Tacos-Fifteen-Spatulas-11.jpg'
  },
  {
    id: 2,
    title: 'Rick A.',
    cost: 400,
    imageUrl: 'https://img.apmcdn.org/768cb350c59023919f564341090e3eea4970388c/normal/2aa134-20180309-rick-astley.jpg'
  }
]

export const taco_employeeTacoData = [
  {
    firstName: 'Matt',
    lastName: 'Vieten',
    email: 'yeah@yeah.com',
    photoUrl: null,
    remainingTacos: 0,
    commentsReceived: [
      { quantity: 2, comment: 'he pretty nice', dateCreated: new Date()}
    ]
  },
  {
    firstName: 'Matt',
    lastName: 'Vieten',
    email: 'yeah@yeah.com',
    photoUrl: null,
    remainingTacos: 0,
    commentsReceived: [
      { quantity: 2, comment: 'he pretty nice'}
    ]
  },
  {
    firstName: 'Javante\'',
    lastName: 'Stoudmire',
    email: 'yeah@yeah.com',
    photoUrl: null,
    remainingTacos: 0,
    commentsReceived: [
      { quantity: 1, comment: 'this is going to be a super long comment to test row wrapping and maybe it will work and maybe it wont but ill blame someone else'},
      { quantity: 3, comment: 'he pretty nice'},
      { quantity: 2, comment: 'he can be really cool and stuff'},
      { quantity: 4, comment: 'i like his shirt'},
      { quantity: 5, comment: 'i hate his shirt'},
    ]
  },
  {
    firstName: 'Javante\'',
    lastName: 'Stoudmire',
    email: 'yeah@yeah.com',
    photoUrl: null,
    remainingTacos: 0,
    commentsReceived: [
      { quantity: 1, comment: 'this is going to be a super long comment to test row wrapping and maybe it will work and maybe it wont but ill blame someone else'},
      { quantity: 3, comment: 'he pretty nice'},
      { quantity: 2, comment: 'he can be really cool and stuff'},
      { quantity: 4, comment: 'i like his shirt'},
      { quantity: 5, comment: 'i hate his shirt'},
    ]
  },
  {
    firstName: 'Javante\'',
    lastName: 'Stoudmire',
    email: 'yeah@yeah.com',
    photoUrl: null,
    remainingTacos: 0,
    commentsReceived: [
      { quantity: 1, comment: 'this is going to be a super long comment to test row wrapping and maybe it will work and maybe it wont but ill blame someone else'},
      { quantity: 3, comment: 'he pretty nice'},
      { quantity: 2, comment: 'he can be really cool and stuff'},
      { quantity: 4, comment: 'i like his shirt'},
      { quantity: 5, comment: 'i hate his shirt'},
    ]
  }
]

export const auth_fullUserObject = {
  firstName: 'Callan',
  lastName: 'Morrison',
  email: 'callan@fortyau.com',
  photoUrl: 'https://lh3.googleusercontent.com/a-/AOh14GhjFkCgwW4xRtSA8zNaevSfU7HLEJNgb8QRDhD-',
  roles: ['admin', 'teamLead', 'partner']  
}

export const you_everything = {
  tacoBalance: 90,
  tacoRemainingBudget: 0,
  tacoComments: [ 'super cool', 'eh, okie dokie', 'yeah sure']
}