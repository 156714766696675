import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useLoadingContext } from './LoadingContextProvider'

const LOGO_URL = `https://firebasestorage.googleapis.com/v0/b/forty-spacetacos.appspot.com/o/spacetacos1.png?alt=media&amp;token=5a87cde5-e7a5-49c4-9ddb-17e1f26c165f`

const useStyles = makeStyles((theme) => ({
  loadingFrame: {
    height: '50vh',
    marginTop: '25vh',
    marginBottom: '25vh'
  },
  logoSpin: {
    maxHeight: '100%',
    animation: `$spin 5000ms infinite linear`
  },
  '@keyframes spin': {
    '0%': {
      transform: "rotate(0deg)"
    },
    "100%": {
      transform: "rotate(360deg)"
    }
  },
}))

export const LoaderElement = () => {
  const classes = useStyles()
  const { loading } = useLoadingContext()

  return loading && (
    <Grid container direction='row' justify='space-around' className={classes.loadingFrame}>
      <img className={classes.logoSpin} src={LOGO_URL} alt='SpaceTacoLogo'/>
    </Grid>
  )
}