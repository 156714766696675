import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  inlineTaco: {
    maxWidth: '1.5em',
    margin: `0 2px`
  }
}))

export const InlineTaco = () => {
  const classes = useStyles()
  
  return (
    <span role='img' aria-label='Taco'>
      <img className={classes.inlineTaco} alt='space taco' src='https://firebasestorage.googleapis.com/v0/b/forty-spacetacos.appspot.com/o/spacetaco.png?alt=media&token=b7ceebe9-1d32-41eb-b80f-d3c6c6932ee1' />
    </span> 
  )
}