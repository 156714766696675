import go from 'axios'
import { getAnyAvatar } from '../data/avatar'

go.defaults.baseURL = `${process.env.REACT_APP_API_DOMAIN}/api`
go.defaults.withCredentials = true;

// MAPPERS
const transformRoles = (userObject) => {
  const rolesIncludes = (roleType) => userObject.roles.find(x => x === roleType)
  return { ...userObject, isAdmin: (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_IS_ADMIN) || !!rolesIncludes('ADMIN'), isPartner: !!rolesIncludes('PARTNER'), isTeamLead: !!rolesIncludes('TEAM_LEAD'), isEngineeringLead: !!rolesIncludes('ENGINEERING_LEAD') }
}

const transformPhotoUrl = (userObject) => ({
  ...userObject,
  photoUrl: !(!userObject.photoUrl || userObject.photoUrl === 'N/A') ? userObject.photoUrl : getAnyAvatar()
})

const UserMapper = (userObject) => transformRoles(transformPhotoUrl(userObject))


export const localLoginEmail = (process.env.REACT_APP_BYPASS_LOGIN_EMAIL && process.env.NODE_ENV !== 'production') ? process.env.REACT_APP_BYPASS_LOGIN_EMAIL : undefined;

export const uploadAndProcessUserData = async(rosterFile) => {
  const formData = new FormData();
  formData.append('file', rosterFile)
  const config = { headers: { 'Content-Type': 'multipart/form-data'  } }
  return go.post(`/admin/employee/load`, formData, config)
}

export const getTacoBudgetCounts = async() => (await go.get(`/admin/roles/allocations`)).data

export const runMonthlySeedFunction = async(admin_tacoBudgetCounts) => go.post(`/admin/seed/monthly`, { tacoBudgetConfiguration: {...admin_tacoBudgetCounts}})

export const getEverythingReportData = async() => (await go.get(`/admin/reports/everything`)).data

export const getUsageReportData = async() => (await go.get(`/admin/reports/usage`)).data

export const getAdminListOfUsers = async() => (await go.get(`/user/list`)).data.users.map(u => ({ ...u, name: `${u.firstName} ${u.lastName}`}))

export const getTypeaheadList = async() => (await go.get(`/user/list/giftable`)).data.users.map(u => ({ ...u, name: `${u.firstName} ${u.lastName}`})).sort((function (x, y) {
  let a = x.name.toUpperCase(),
      b = y.name.toUpperCase();
  return a === b ? 0 : a > b ? 1 : -1;
}))

export const getUserBalance = async(employeeId) => (await go.get(`/admin/employee/${employeeId}/balance`)).data

export const getDetailedUserList = async() => (await go.get(`/admin/users`)).data

export const updateUserList = async(users) => await go.post(`/admin/update/users`, { users })

export const getUserToken = async ({token}) => UserMapper((await go.post(`/user/login`, localLoginEmail, {
  headers: { 'Authorization': `Bearer ${token}` }
})).data)

export const signCompliance = async () => (await (go.post(`/user/compliance/accept`))).data
export const getAllUsersWithTacosToGive = async() => (await (go.get('/taco/users/available'))).data

export const getAllUsersWithReceivedTacos = async() => (await (go.get('/taco/users/gifts'))).data

export const getTopTacoChamp = async() => (await go.get(`/taco/champs/top/previousmonth`)).data

export const sendTaco = async(ownerEmail, { recipientEmail, tacoCount, comment }) => go.post(`/taco/gift`, { ownerEmail, recipientEmail, tacoCount, comment })

export const awardTaco = async(ownerEmail, { recipientEmail, tacoCount, comment }) => go.post(`/taco/award`, { ownerEmail, recipientEmail, tacoCount, comment })

export const getUserEverything = async() => (await go.get('/user/data/taco')).data

export const getSwagStoreItems = async() => (await go.get(`/swag/available`)).data

export const postSwagTransaction = async(employeeId, cost) => go.post(`/swag/transaction`, { swagTransaction: { employeeId, cost } })

export const addNewSwagItem = async(swagItem) => go.post(`/swag`, { swagItem })

export const updateSwagItemList = async(swagItemList) => go.put(`/swag`, { swagItemList })

export const initiateSwagOrder = async(swagId, cost, sizeSelection) => go.post(`/swag/create-order`, { swagId, cost, sizeSelection })

export const getUserSwagOrders = async() => (await go.get(`/swag/my-orders`)).data

export const getOpenSwagOrders = async() => (await go.get(`/swag/open-orders`)).data

export const fulfillSwagOrder = async(orderId) => await go.get(`/swag/fulfill-order/${orderId}`)

export const cancelSwagOrder = async(orderId) => await go.get(`/swag/cancel-order/${orderId}`)



