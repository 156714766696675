import React from 'react'
import { Grid, Slider, Typography } from '@material-ui/core'

export const NumberPicker = ({ id, label, min, max, handleChange, value }) => {
  return (
    <Grid container direction='row' justify='space-between' alignItems='center'>
      <Grid item xs={4}><Typography id={id} gutterBottom color='textSecondary'>{label}</Typography></Grid>
      <Grid item xs={7}>
        <Slider
          value={value}
          defaultValue={1}
          aria-labelledby='taco-amt-slider'
          valueLabelDisplay='on'
          onChange={handleChange}
          step={1}
          marks
          min={min}
          max={max}
        />
      </Grid>  
    </Grid>
  )
}