import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'
import { PrimaryContainer } from '../../../shared/PrimaryContainer'
import { Checkbox, Fab, TextField, CircularProgress  } from '@material-ui/core'
import { Add, Save } from '@material-ui/icons'

const checkboxOptions = (valUpdateCallback) => ({
  filterType: 'checkbox',
  sort: false,
  customBodyRender: (value, { rowIndex, columnIndex, currentTableData }) => {
    return (
      <Checkbox
        checked={value}
        value={value}
        onChange={(e) => {
          const refreshedData = [...currentTableData]
          const updatedRow = refreshedData[rowIndex]
          updatedRow.data[columnIndex] = !value
          const [id] = updatedRow.data;
          valUpdateCallback(refreshedData, id)
        }}
      />
    );
  }
})

const toggleTextInputOptions = (valUpdateCallback) => ({
  customBodyRender: (value, { rowIndex, columnIndex, currentTableData }) => {
    return (
      <TextField 
        size='small' 
        value={value} 
        onChange={(e) => {
          const refreshedData = [...currentTableData]
          const updatedRow = refreshedData[rowIndex]
          updatedRow.data[columnIndex] =  e.target.value
          const [id] = updatedRow.data;
          valUpdateCallback(refreshedData, id)
        }}
      />
    )
  }
})

const genCheckboxCol = (name, label, options, valUpdateCallback) => ({
  name, 
  label, 
  options: {
    ...checkboxOptions(valUpdateCallback),
    customFilterListOptions: { 
      render: (v) => v ? options[0] : options[1]
    }
  }
})

const userMgmtColumns = (valUpdateCallback) => ([
  { name: 'id', options: { display: false }},
  { name: 'firstName', label: 'First', options: toggleTextInputOptions(valUpdateCallback) },
  { name: 'lastName', label: 'Last', options: toggleTextInputOptions(valUpdateCallback) },
  { name: 'email', label: 'Email', options: toggleTextInputOptions(valUpdateCallback) },
  genCheckboxCol('isActive', 'Active?', ['Active', 'Not active'], valUpdateCallback),
  genCheckboxCol('declineGifts', 'Declines gifts?', ['Declines gifts', 'Doesn\'t decline gifts'], valUpdateCallback),
  genCheckboxCol('teamLead', 'Team Lead?', ['Is team lead', 'Not team lead'], valUpdateCallback),
  genCheckboxCol('engineeringLead', 'Engineering Lead?', ['Is EL', 'Not EL'], valUpdateCallback),
  genCheckboxCol('partner', 'Partner?', ['Is partner', 'Not partner'], valUpdateCallback),
  genCheckboxCol('admin', 'Admin?', ['Is admin', 'Not admin'], valUpdateCallback) 
])

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    top: '5%',
    right: '5%',
    zIndex: 700
  }
}))

const transformToColumns = (userList) => {
  return userList.map((u) => ([
    u.id,
    u.firstName,
    u.lastName,
    u.email,
    u.isActive,
    u.declineGifts,
    u.teamLead,
    u.engineeringLead,
    u.partner,
    u.admin
  ]))
}

export const UserMgmtPage = ({ savePending, saveUserList, userList, userUpdatedCallback }) => {
  const [updatedUsersList, setUpdatedUserList] = useState(new Set());
  const [updatedUserState, setUpdatedUserState] = useState(transformToColumns(userList) || []);
  const classes = useStyles()

  const valUpdateCallback = (tableMeta, userId) => {
    setUpdatedUserList(prev => new Set(prev.add(userId)));
    const formattedTableData = tableMeta.map(r => r.data)
    setUpdatedUserState(formattedTableData)
  }

  const addNewEmployee = () => {
    setUpdatedUserList(prev => new Set(prev.add(null)));
    setUpdatedUserState([[ null, '', '', '', true, false, false, false, false, false ], ...updatedUserState])
  }

  const saveUpdates = () => {
    const onlyUpdatedUsers = updatedUserState
      .filter(([id]) => updatedUsersList.has(id));
    saveUserList(onlyUpdatedUsers)
  }

  return (
    <PrimaryContainer mainTitle='Admin'>
        <MUIDataTable
          title='User Management'
          columns={userMgmtColumns(valUpdateCallback)}
          data={updatedUserState}
          options={{
            fixedHeader: true,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: 'none',
            fixedSelectColumn: false,
            rowsPerPage: 15
          }}
        />
      <div className={classes.fab}>
        <Fab variant='extended' onClick={saveUpdates} color='secondary' disabled={savePending}>
          <Save className={classes.extendedIcon} />
          Save
          {savePending && (
            <CircularProgress color='secondary' size={16} />
          )}
        </Fab>
        <Fab variant='extended' onClick={addNewEmployee} color='secondary'>
          <Add className={classes.extendedIcon} />
          Add New Employee
        </Fab>
      </div>
    </PrimaryContainer>
  )
}