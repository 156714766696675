import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AutoRotatingCarousel } from 'material-auto-rotating-carousel'
import { Box, Button, Card, CardContent, CardMedia, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, Tab, Tabs, TextField, Typography } from '@material-ui/core'
import { DoneOutlined, LockOutlined, MoreHoriz } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { getUserEverything, getUserSwagOrders, initiateSwagOrder } from '../../service/dataCalls'
import { PrimaryContainer } from '../../shared/PrimaryContainer'
import { InlineTaco } from '../../shared/InlineTaco'
import { useSwagItems, IMAGE_JOINER } from '../../shared/useSwagItems'
import { useLoadingContext } from '../../shared/LoadingContextProvider'
import { getAnyAvatar } from '../../data/avatar'
import { useAuthDataContext } from '../Authentication/AuthDataContextProvider'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    '& > *': {
      margin: theme.spacing(2),
      height: `calc(100vh - ${theme.spacing(4)}px)`
    }
  },
  profileRow: {
    marginTop: theme.spacing(3),
    overflow: 'visible',
    '&:first-child': {
      marginTop: theme.spacing(0)
    }
  },
  tacoCommentsRow: {
    marginTop: theme.spacing(3),
    overflow: 'auto',
  },
  profileImg: {
    maxWidth: '60px',
    verticalAlign: 'text-bottom',
    borderRadius: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  headerSection: {
    height: '30%'
  },
  contentSection: {
    borderRadius: '4px',
    width: '100%',
    height: '60%',
    backgroundColor: theme.palette.primary.main
  },
  tabPanel: {
    height: 'calc(100% - 48px)',
    overflow: 'auto'
  },
  noneYetMessage: {
    width: '80%',
    opacity: 0.3,

    '& img': {
      width: '80%',
      margin: '0 10%'
    }
  },
  swagOrderList: {
    width: '100%'
  },
  swagOrderLineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  swagOrderTitle: {
    marginLeft: '4px',
    width: '60%'
  },
  imageContainer: {
    height: '48px',
    width: '48px'
  },
  swagOrderImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  swagCard: {
    width: '300px',
    marginBottom: theme.spacing(4)
  }, 
  swagImage: {
    height: '150px'
  },
  swagCardContent: {
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  marginBottom: {
    marginBottom: theme.spacing(4)
  },
  carousel: {
    position: 'absolute',
    width: '70%',
    top: `20%`,
    left: '15%',
  },
  carouselImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: '50% 50%'
  },
  moreImagesIcon: {
    position: 'relative',
    top: '130px',
    left: '45%',
    fill: 'white'
  }
}))




const NoneYetMessage = ({ appearance, imageSrc, entity }) => (
  <Grid direction='column' justify='center' className={appearance}>
    <Grid item>
      <img src={imageSrc} alt='' />
    </Grid>
    <Grid item>
      <Typography variant='h6' align='center'>No {entity} ... yet.</Typography>
    </Grid>
  </Grid>
) 

const TabPanel = ({ children, classes, value, index }) => (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={classes.tabPanel }
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
);

const SwagOrderLine = ({ classes, swagOrder }) => (
  <div className={classes.swagOrderLineItem}>
    <div className={classes.imageContainer}>
      <img src={swagOrder.swag.imageUrl.split(IMAGE_JOINER)[0]} alt={swagOrder.swag.title} className={classes.swagOrderImage}/>
    </div>
    <Typography className={classes.swagOrderTitle}>{swagOrder.swag.title}{swagOrder.sizeSelection && ` (${swagOrder.sizeSelection})`}</Typography>
    <Grid direction='column'>
      <Typography>{new Date(swagOrder.dateCreated).toLocaleDateString('en-gb',
          {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}
      </Typography>
      {
        swagOrder.cancelled ? (
          <Typography style={{ color: '#b42f30' }}>Cancelled</Typography>
        ) : swagOrder.fulfilled ? (
          <Typography variant='colorSecondary'>Fulfilled</Typography>
        ) : (
          <Typography variant='colorSecondary'>Pending</Typography>
        )
      }
    </Grid>
    
  </div>
)

const SwagItem = ({ classes, swagItem, userEverythingData, initiateSwagRequest }) => {
  const [carouselOpen, setCarouselOpen] = useState(false)
  const [dialogueOpen, setDialogueOpen] = useState(false)
  const [swagSizeSelection, setSwagSizeSelection] = useState(null)

  const balanceExceedsCost = swagItem.cost && swagItem.cost <= userEverythingData.tacoBalance

  const submitSwagRequest =  async(swagSizeSelection) => {
    await initiateSwagRequest(swagSizeSelection)
    setSwagSizeSelection(null)
    setDialogueOpen(false)
  }

  return (                  
    <Card className={classes.swagCard}>
      <CardMedia
        className={classes.swagImage}
        image={swagItem.images[0]}
        title={swagItem.title}
        onClick={() => setCarouselOpen(true)}
      >
          {
            (swagItem.images.length > 1) && <MoreHoriz className={classes.moreImagesIcon} color='secondary'/>
          } 
      </CardMedia>
      <AutoRotatingCarousel
        autoplay={false}
        label={swagItem.title}
        open={carouselOpen}
        onClose={() => setCarouselOpen(false)}
      >
      {
        swagItem.images.map((item, i) => (
          <img className={classes.carouselImg} key={i} src={item} alt={swagItem.title}/>
        ))
      }
      </AutoRotatingCarousel>
        <CardContent className={classes.swagCardContent}>
            <Typography gutterBottom color='textSecondary' align='center'>
              {swagItem.title}
            </Typography>
            <Grid container direction='row' justify='center' alignItems='center'>
              <Grid item>
                <Typography color='textSecondary' component='p'>
                  {swagItem.cost || 'Coming soon!'} <InlineTaco />
                </Typography>
              </Grid>
              <Grid item> {  balanceExceedsCost ? <DoneOutlined /> : <LockOutlined /> } </Grid>
            </Grid>
            <Button onClick={() => setDialogueOpen(true)} color='secondary' variant='contained' disabled={!balanceExceedsCost}>Purchase</Button>
            <Dialog
              open={dialogueOpen}
              onClose={() => setDialogueOpen(false)}
            >
              <DialogTitle>Purchase this swag item?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  This is gonna cost you {swagItem.cost} <InlineTaco />s. Processing time is 1-3 weeks.
                </DialogContentText>
                {
                  swagItem.hasSizeOptions && (
                    <Autocomplete 
                      id='swagSizeSelection'
                      options={['XS', 'S', 'M', 'L', 'XL', 'XXL']}
                      onChange={(_, newInputValue) => {
                        if (newInputValue != null) {
                          setSwagSizeSelection(newInputValue)
                        }
                      }}
                      className={classes.formChild}
                      fullWidth
                      renderInput={(params) => <TextField {...params} value={swagSizeSelection} label='Size?' variant='outlined' />}
                    />
                  )
                }
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogueOpen(false)} color='secondary'>
                  Cancel
                </Button>
                <Button onClick={() => submitSwagRequest(swagSizeSelection)} autoFocus color='primary'>
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
        </CardContent>
    </Card>
  )
}

const YouPage = () => {
  const classes = useStyles()
  const [userEverythingData, setUserEverythingData] = useState(null)
  const [swagOrders, setSwagOrders] = useState([])
  const [tabIndex, setTabIndex] = useState(0)
  const { user, logout, token } = useAuthDataContext()
  const { setLoading } = useLoadingContext()
  const history = useHistory()
  const { swagItems } = useSwagItems()

  const loadUserPageData = useCallback(async() => {
    if (token) {
      const userEverythingDataResults = await getUserEverything(token)
      setUserEverythingData(userEverythingDataResults)
      const swagOrders = (await getUserSwagOrders()).sort((a, b) => {
        const aDate = a.dateModified || a.dateCreated
        const bDate = b.dateModified || b.dateCreated
        return aDate > bDate ? -1 : 1
      })
      setSwagOrders(swagOrders)
    }
  }, [token])

  useEffect(() => {
    if (!userEverythingData) {
      setLoading(true) 
    } else {
      setLoading(false) 
    }
  }, [userEverythingData, setLoading])

  const submitSwagOrder = async(swagId, swagCost, sizeSelection) => {
    await initiateSwagOrder(swagId, swagCost, sizeSelection)
    loadUserPageData()
  }

  useEffect(() => { loadUserPageData() }, [loadUserPageData])

  return (
    <>
      <CssBaseline />
      <Grid container direction='row' justify={'space-around'} className={classes.container}>
        <Grid item lg={4} md={5} sm={10} xs={12}>
          <PrimaryContainer mainTitle='You'>
          {
            userEverythingData && (
              <>
                <div className={classes.headerSection}>
                  <Grid container direction='row' justify='center' alignItems='center' className={classes.profileRow}>
                    <Grid item > 
                      <img className={classes.profileImg} src={user.photoUrl || getAnyAvatar()} alt={`User ${user.firstName}}`} />
                    </Grid>
                    <Grid item >
                      <Typography variant='h6'>You are {user.firstName} {user.lastName}.</Typography>
                      <Typography>But you knew that already.</Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' justify='center' alignItems='center' className={classes.profileRow}>
                    <Grid item > 
                      <img className={classes.profileImg} src='https://firebasestorage.googleapis.com/v0/b/forty-spacetacos.appspot.com/o/spacetaco.png?alt=media&token=b7ceebe9-1d32-41eb-b80f-d3c6c6932ee1' alt={`Taco for spending`} />
                    </Grid>
                    <Grid item >
                      <Typography variant='h6'>You have {userEverythingData.tacoBalance || 'NO'}</Typography>
                      <Typography>taco(s) to spend on swag.</Typography>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.contentSection}>
                  <Tabs
                    value={tabIndex}
                    variant='fullWidth'
                    indicatorColor='secondary'
                    textColor='secondary'
                    onChange={(_, newValue) => setTabIndex(newValue)}
                  >
                    <Tab label='Swag Orders'/>
                    <Tab label='Praise' />
                  </Tabs>
                  <TabPanel value={tabIndex} index={0} classes={classes}>
                    <Grid container direction='column' wrap='nowrap' alignItems='center' className={classes.tacoCommentsRow}>
                      {swagOrders && swagOrders.length ? (
                        <List className={classes.swagOrderList}>
                          {swagOrders.map((swagOrder) => (
                            <ListItem>
                              {
                                swagOrder.swag && (<SwagOrderLine classes={classes} swagOrder={swagOrder} />)
                              }
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <NoneYetMessage appearance={classes.noneYetMessage} entity='swag orders' imageSrc={`https://firebasestorage.googleapis.com/v0/b/forty-spacetacos.appspot.com/o/thanksgiving-1.png?alt=media&token=e54cbf15-0ca7-4f51-9349-7e6dfa985f67`} />
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1} classes={classes}>
                    <Grid container direction='column' wrap='nowrap' alignItems='center' className={classes.tacoCommentsRow}>
                      {userEverythingData.tacoComments && userEverythingData.tacoComments.length ? (
                          <List>
                          {
                            userEverythingData.tacoComments.map(comment => (
                              <ListItem><Typography><span role='img' aria-label='Comment'>💬</span> {comment.comment}</Typography></ListItem>
                            ))
                          }
                        </List>
                        ) : (
                          <NoneYetMessage appearance={classes.noneYetMessage} entity='praise' imageSrc={`https://firebasestorage.googleapis.com/v0/b/forty-spacetacos.appspot.com/o/valentines.png?alt=media&token=57eb5b27-816a-440a-8831-f4254ceaad6c`} />
                        )}
                    </Grid>
                  </TabPanel>
                </div>
                <Grid container direction='row' justify='space-around' alignItems='center' className={classes.profileRow}>
                  <Grid item > 
                    <Button onClick={logout} color='secondary' variant='contained'>Logout</Button>
                  </Grid>
                  <Grid item > 
                    <Button onClick={() => history.push('/')} color='secondary' variant='contained'>Dashboard</Button>
                  </Grid>
                </Grid>
              </>
            ) 
          }
          </PrimaryContainer>
        </Grid>
        <Grid item lg={7} md={6} sm={10} xs={12}>
          <PrimaryContainer mainTitle={`'Taco Truck'`} mainSubtitle='Your purchase will be processed within 1-3 weeks.'>
            <Grid container direction='row' justify='space-around'>
              {
                swagItems && swagItems.sort((t, pt) => pt.display_order - t.display_order).map(swagItem => (
                  userEverythingData && <SwagItem classes={classes} swagItem={swagItem} userEverythingData={userEverythingData} initiateSwagRequest={async(sizeSelection) => await submitSwagOrder(swagItem.id, swagItem.cost, sizeSelection)}/>
                ))
              }
            </Grid>
          </PrimaryContainer>
        </Grid>
      </Grid>
    </>
  )
}

export default YouPage