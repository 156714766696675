import  React, { useState } from 'react'
import { Grid, CssBaseline, Container, Typography   } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { InlineTaco } from '../../shared/InlineTaco'
import { useAuthDataContext } from './AuthDataContextProvider'

const LOGO_URL = `https://firebasestorage.googleapis.com/v0/b/forty-spacetacos.appspot.com/o/spacetacos1.png?alt=media&amp;token=5a87cde5-e7a5-49c4-9ddb-17e1f26c165f`

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    position: 'relative',
    height: '100vh'
  },
  paper: {
    position: 'absolute',
    top: '40vh',
    left: '30%',
    width: '40%',
    transform: `translateY(-50%)`,
    padding: 15,
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.dark,
    boxShadow: `0px 5px 5px ${theme.palette.primary.main}`,
    borderRadius: 5,
    borderTop: `1px solid ${theme.palette.primary.light}`
  },
  loginHeader: {
    textAlign: 'center'
  },
  loginHeaderImage: {
    width: 75,
    marginBottom: 10
  },
  submitInstructions: {
    fontSize: 20,
    letterSpacing: 1,
    fontWeight: 700,
    textAlign: 'center'
  },
  submit: {
    position: 'relative',
    width: '80%',
    padding: '20px 75px',
    margin: '20px 10%',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    fontSize: 20,
    letterSpacing: 1,
    fontWeight: 700,
    transition: 'all 0.3s',

    '&:before': {
      position: 'absolute',
      top: 20,
      left: '65%',
      fontWeight: 900,
      fontFamily: '"Font Awesome 5 Free"',
      transition: 'all 0.3s',
      opacity: 0,
      content: '"\\f061"'
    },

    '&:focus': {
      color: theme.palette.primary.contrastText
    },

    '&:active': {
      backgroundColor: theme.palette.primary.dark,
    },

    '&:active:before': {
      color: theme.palette.secondary.main
    },

    '&:hover:before': {
      left: '75%',
      opacity: 1,
    }
  }
}))

export const Login = () => {
  const classes = useStyles()
  const [error, setError] = useState(null)
  const { login, authError } = useAuthDataContext()

  const useGoogleLogin = (async() => {
    try {
      login()
    } catch (e) {
      setError(e.message)
    }
  })

  return (
    <Container component='main' className={classes.loginContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Grid className={classes.loginHeader}>
            <img className={classes.loginHeaderImage} src={LOGO_URL} alt='SpaceTacoLogo'/>
            <Typography variant='h5' >GET YOUR <InlineTaco/> ON</Typography>
        </Grid>
        <Grid>
          <Typography className={classes.submitInstructions}>LOGIN IN WITH YOUR</Typography>
          <button
            className={classes.submit}
            onClick={useGoogleLogin}
          > 40AU GMAIL </button>
        </Grid>
        <Typography color='error'>{error || authError}</Typography>
      </div>
    </Container>
  )
}
