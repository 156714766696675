import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import firebase from 'firebase/app'
import 'firebase/auth'
import { localLoginEmail, getUserToken } from '../../service/dataCalls'
import { connection } from '../../data/fbConfig'

export const AuthDataContext = createContext({})

export const AuthDataProvider = props => {
  const [userLoading, setUserLoading] = useState(false)
  const [authError, setAuthError] = useState(null)
  const [credentials, setCredentials] = useState(null)
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const history = useHistory()
  // eslint-disable-next-line no-unused-vars
  const [_, setCookie] = useCookies();

  const addUserDataFirebase = useCallback(async () => {
    if (credentials) {
      const credentials = await firebase.auth().currentUser.getIdToken(true)
      try {
        const fullUserData = await getUserToken({ token: credentials })
        setUser(fullUserData)
      } catch (e) {
        firebase.auth().signOut()
        setAuthError('Failed to add user data. Contact your HR rep (😉) to make sure your email is on the application roster.')
      }
    }
    setUserLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const addUserDataLocalAuth = useCallback(async () => {
    if (!credentials) {
      try {
        const fullUserData = await getUserToken({})
        setUser(fullUserData)
      } catch (e) {
        setAuthError('Failed to add user data. Verify email matches user in DB.')
      }
    }
  }, [credentials])

  const logout = async () => {
    await firebase.auth().signOut()
    setCredentials(null)
    setUser(null)
    history.push('/login')
  }

  const login = async () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    await firebase.auth().signInWithPopup(provider)
    await addUserDataFirebase()
  }

  const localLoginUserUpdate = useCallback(async () => {
    setToken(localLoginEmail)
    setCredentials(localLoginEmail)
    await addUserDataLocalAuth()
    setUserLoading(false)
  }, [addUserDataLocalAuth])

  const updateUser = async() => {
    if (!localLoginEmail) {
      addUserDataFirebase()
    } else {
      addUserDataLocalAuth()
    }
  }

  const firebaseUserUpdate = useCallback(() => {
    firebase.auth().onAuthStateChanged(async (u) => {
      if (!(u && user)) {
        setCookie('authenticated', false, { expires: new Date(), domain: 'spaceta.co' })
        setCookie('session', false, { expires: new Date(), domain: 'spaceta.co' })
        setUserLoading(false)
      }

      if (u && !user) {
        try {
          await addUserDataFirebase()
          const credentials = await firebase.auth().currentUser.getIdToken(true)
          setToken(credentials)
          setCredentials(credentials)
        } catch (e) {
          console.error(e)
        }
      }
    })
  }, [user, setCookie, addUserDataFirebase])

  useEffect(() => {
    connection()
    if (!user) {
      setUserLoading(true)
    } else {
      const hasAcceptedComplianceAgreement = user.acceptedCompliance;
      history.push(hasAcceptedComplianceAgreement ? '/' : '/compliance')
    }

    if (localLoginEmail) {
      localLoginUserUpdate()
    } else {
      firebaseUserUpdate()
    }
  }, [user, addUserDataFirebase, firebaseUserUpdate, localLoginUserUpdate, history])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const authDataValue = useMemo(() => ({ userLoading, user, logout, login, token, authError, updateUser }), [userLoading, user, token, authError])

  return <AuthDataContext.Provider value={authDataValue} {...props} />
}

export const useAuthDataContext = () => useContext(AuthDataContext)