import React, { useCallback, useEffect, useState } from 'react'
import { Alert } from '@material-ui/lab'
import { useLoadingContext } from '../../../shared/LoadingContextProvider'
import { getDetailedUserList, updateUserList } from '../../../service/dataCalls'
import { UserMgmtPage } from './UserMgmtPage'

export const UserMgmt = () => {
  const [userList, setUserList] = useState(null)
  const [savePending, setSavePending] = useState(false)
  const [error, setError] = useState(null)
  const { setLoading } = useLoadingContext()

  const setAdminUserListData = useCallback(async() => {
    try {
      const usersWithAdminData = (await getDetailedUserList()).users
      setUserList(usersWithAdminData.sort((x, y) => x.lastName.localeCompare(y.lastName)))
      setError(null)
    } catch (err) {
      setError(`Failed to load users, try again or hit up one of the support devs👩‍💻: ${err.message}`)
    }
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setAdminUserListData() }, []);

  useEffect(() => {
    if (!userList) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [userList, setLoading])

  const saveUserList = async (updated) => {
    const mappedUpdates = updated.map(up => {
      const i = userList.findIndex(u => u.id === up[0])
      const userDetails =  i >= 0 ? ({ ...userList[i] }) : ({
        fullName: `${up[1]} ${up[2]}`,
        googleId: null,
        id: null,
        photoUrl: 'N/A',
        slackId: null
      })
      return ({
        ...userDetails,
        firstName: up[1],
        lastName: up[2],
        email: up[3],
        isActive: up[4],
        declineGifts: up[5],
        teamLead: up[6],
        engineeringLead: up[7],
        partner: up[8],
        admin: up[9],
        roles: [
          up[6] && "TEAM_LEAD", 
          up[7] && "ENGINEERING_LEAD", 
          up[8] && "PARTNER", 
          up[9] && "ADMIN"
        ].filter(Boolean)
      })
    }).filter(u => u.email && u.firstName && u.lastName)
    setSavePending(true)
    try {
      await updateUserList(mappedUpdates)
      setUserList(null)
      setAdminUserListData()
      setError(null)
    } catch (err) {
      setError(`Failed to save, try again or hit up one of the support devs👩‍💻: ${err.message}`)
    }
    setSavePending(false)
  }

  return (
    <>
      { error && <Alert severity='error'>{error}</Alert> }
      { userList && 
        <UserMgmtPage 
          {...{
            savePending,
            saveUserList,
            userList
          }}
        />}
    </>
  )
}