import  React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { ThemeProvider } from '@material-ui/styles'

import { AuthDataProvider, useAuthDataContext } from './pages/Authentication/AuthDataContextProvider'
import { Login } from './pages/Authentication/Login'
import { Compliance } from './pages/Authentication/Compliance/ComplianceContainer'
import { Main } from './pages/Main/MainContainer'
import { UserMgmt } from './pages/Admin/UserMgmt/UserMgmtContainer'
import You from './pages/You/YouPage'
import { LoadingProvider, useLoadingContext } from './shared/LoadingContextProvider'
import { LoaderElement } from './shared/LoadingScreen'
import { theme } from './styles/theme'


export const App = () => {
  const PrivateRoute = ({ component, ...options }) => {
    const { user, userLoading } = useAuthDataContext()
    const { setLoading } = useLoadingContext()

    userLoading ? setLoading(true) : setLoading(false)

    if (user && !userLoading && (user.acceptedCompliance || options.complianceFree)) {
      if (options.isAdmin && !user.admin) {
        return <Redirect to='/'/>
      }
      return (<Route {...options} component={component}/>)
    } else {
      return <Login />
    }
  }

  const AdminRoute = (props) => <PrivateRoute isAdmin {...props}/>;
  
  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <LoaderElement />
        <Router>
          <AuthDataProvider>
            <Switch>
              <Route path='/login'>
                <Login />
              </Route>
              <PrivateRoute path='/compliance' complianceFree>
                <Compliance />
              </PrivateRoute>
              <PrivateRoute path='/office'>
                <Main isPublicView/>
              </PrivateRoute>
              <AdminRoute path='/admin/user-mgmt'>
                <UserMgmt />
              </AdminRoute>
              <PrivateRoute path='/you'>
                <You />
              </PrivateRoute>
              <PrivateRoute path='/'>
                <Main/>
              </PrivateRoute>
            </Switch>
          </AuthDataProvider>
        </Router>
      </LoadingProvider>
    </ThemeProvider>
  )
}

export default App
