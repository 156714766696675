import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { InlineTaco } from './InlineTaco'

const useStyles = makeStyles((theme) => ({
  countdownWrapper: {
    backgroundColor: theme.palette.primary.dark,
    margin: theme.spacing(3)
  },
  countdownItem: {
    color: theme.palette.secondary.dark,
    fontSize: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    lineHeight: '30px',
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    position: 'relative',
    width: '100px',
    height: '90px'
  }
}))

export const Countdown = () => {
  const classes = useStyles()
  const [timeRemaining, setTimeRemaining] = useState({ days: null, hours: null, minutes: null, seconds: null })

  useEffect(() => {
    const interval = setInterval(() => {
      const then = moment().endOf('month');
      const now = moment();
      const countdown = moment(then - now);
      const days = countdown.format('D');
      const hours = countdown.format('HH');
      const minutes = countdown.format('mm');
      const seconds = countdown.format('ss');
      setTimeRemaining({ days, hours, minutes, seconds });
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  })

  return (
    <Grid container direction='column' justify='center' alignItems='center' className={classes.countdownWrapper}>
      <Grid item container direction='row' justify='center' alignItems='center' wrap >
          <Grid item className={classes.countdownItem}>
              {timeRemaining.days}
              <Typography variant='subtitle2'>days</Typography>
          </Grid>
          <Grid item className={classes.countdownItem}>
              {timeRemaining.hours}
              <Typography variant='subtitle2'>hours</Typography>
          </Grid>
          <Grid item className={classes.countdownItem}>
              {timeRemaining.minutes}
              <Typography variant='subtitle2'>minutes</Typography>
          </Grid>
          <Grid item className={classes.countdownItem}>
              {timeRemaining.seconds}
              <Typography variant='subtitle2'>seconds</Typography>
          </Grid>
      </Grid>
      <Grid item container justify='center' alignItems='center'> 
        <Typography variant='h5'> Left to give out your <InlineTaco />(s) for this month</Typography>
      </Grid>
  </Grid>
  )
}