import  React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, FormControl, Grid, TextField, Typography } from '@material-ui/core'
import { AccountBox } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { NumberPicker } from '../../../shared/NumberPicker'
import { PrimaryContainer } from '../../../shared/PrimaryContainer'
import { getAnyAvatar } from '../../../data/avatar'

export const MainGiving = ({
  classes, 
  user,
  fullUserData, 
  handleSendTaco, 
  tacoTypeaheadUsers, 
  workingTaco, 
  setTacoTypeaheadSelection, 
  handleChange, 
  tacoTypeaheadSelection 
}) => {
  const history = useHistory()

  return (
  <PrimaryContainer mainTitle='Give A Taco'>
    <Typography color='textPrimary' align='center'>Recognize your peers for the big and the small; give them a taco, give them a few <span role='img' aria-label='taco'>🌮</span>(s). Let them know you appreciate them, and let the whole company know why you think they rock. Your taco supply will be replenished monthly.</Typography>
    
    <Grid container direction='row' justify='space-evenly' alignItems='center' className={classes.paddedRow}>
      <Typography variant='h5'>Hello,
        <img className={classes.profileImg} src={user.photoUrl || getAnyAvatar()} alt={`User ${user.firstName}}`} />{user.firstName}.
      </Typography>
      <Button
        color='secondary'
        variant='contained'
        startIcon={<AccountBox />}
        onClick={() => history.push('/you')}>
          View your profile.
      </Button>
    </Grid>
    
    <Typography variant='h6'>You have {fullUserData ? fullUserData.tacoRemainingBudget : '(hold up)'} taco(s) remaining this month</Typography>

    {!!(fullUserData && fullUserData.tacoRemainingBudget && tacoTypeaheadUsers) && (
      <FormControl onSubmit={handleSendTaco} color='secondary' fullWidth>
      <Grid container direction='column' className={classes.form}>
        <Grid item>
          <Autocomplete 
            id='recipientEmail'
            key='coolio'
            options={tacoTypeaheadUsers}
            getOptionLabel={(option) => option.name}
            onChange={(_, newInputValue) => {
              if (newInputValue != null) {
                setTacoTypeaheadSelection(newInputValue)
                handleChange('recipientEmail', newInputValue, 'email')
              }
            }}
            className={classes.formChild}
            fullWidth
            renderInput={(params) => <TextField {...params} value={tacoTypeaheadSelection} label='Who gets taco(s)' variant='outlined' />}
          />
        </Grid>
        <Grid item className={classes.formChild}>
          <NumberPicker
            id='taco-amt-picker'
            label='How many taco(s)?'
            value={workingTaco.tacoCount}
            handleChange={(_, newInputValue) => { !!newInputValue && handleChange('tacoCount', newInputValue) }}
            min={1}
            max={fullUserData.tacoRemainingBudget}/>
        </Grid>
        <Grid item className={classes.formChild}><TextField multiline fullWidth rows={3} label='Why gets taco(s)' id='comment' variant='outlined'  value={workingTaco.comment} onChange={(e) => handleChange('comment', e.target.value)} /></Grid>
        <Grid item><Button type='submit' onClick={handleSendTaco} color='secondary' variant='contained' disabled={!workingTaco.recipientEmail}> Give {workingTaco.tacoCount} Taco(s) </Button></Grid>
      </Grid>
      </FormControl>
    )}

  </PrimaryContainer>
  )
}