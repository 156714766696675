import { createMuiTheme } from '@material-ui/core/styles'

const DARK_BLUE = '#222857'
const MED_BLUE = '#3B4384'
const LIGHT_BLUE = '#4E5ABB'
const DARK_MUSTARD = '#DBAF00'
const WHITE = '#8E96D8'
const FORM_WHITE = '#d7d7d7'
const DEEP_RED = '#b42f30'


export const theme = createMuiTheme({
  body: {
    backgroundColor: MED_BLUE
  },
  typography: {
    fontFamily: 'Muli, sans-serif',
  },
  palette: {
    primary: {
      light: LIGHT_BLUE,
      main: MED_BLUE,
      dark: DARK_BLUE,
      contrastText: WHITE
    },
    secondary: {
      main: DARK_MUSTARD,
      contrastText: DARK_BLUE,
      light: FORM_WHITE
    },
    text: {
      primary: WHITE,
      secondary: DARK_BLUE
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: MED_BLUE,
          fontFamily: 'Muli, sans-serif'
        }
      }
    },
    MuiTypography: {
      colorError: {
        color: DEEP_RED,
        padding: '30px'
      }
    },
    MuiOutlinedInput: {
      root: {
        color: DARK_BLUE,
      }
    },
    MuiAutocomplete: {
      paper: {
        color: DARK_BLUE,
      }
    },
    MuiSvgIcon: {
      root: {
        fill: DARK_BLUE
      }
    },
    MuiDropzoneArea: {
      root: {
        backgroundColor: WHITE,
        minHeight: '85px',
        '&:focus': {
          outline: `2px solid ${LIGHT_BLUE}`
        }
      }
    },
    MUIDataTableBodyCell: {
      root: {
        color: DARK_BLUE
      }
    },
    MUIDataTableHeadCell: {
      root: {
        color: DARK_BLUE
      },
      sortActive: {
        color: DARK_BLUE
      }
    },
    MuiInputBase: {
      root: {
        color: DARK_BLUE
      }
    }
  }
})