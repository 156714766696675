import  React from 'react'
import { CssBaseline, Grid, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Admin } from '../Admin/AdminContainer'
import { MainRemaining } from './Components/MainRemaining'
import { MainGiving } from './Components/MainGiving'
import { MainStanding, MainStandingBeautiful } from './Components/MainStandings'


const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    '& > *': {
      margin: theme.spacing(2),
      height: `calc(100vh - ${theme.spacing(4)}px)`
    }
  },
  paddedRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  profileImg: {
    maxWidth: '30px',
    verticalAlign: 'text-bottom',
    borderRadius: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  profileImgLarge: {
    maxWidth: '45px',
    verticalAlign: 'text-bottom',
    borderRadius: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  form: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '400px',
  },
  formChild: {
    color: theme.palette.primary.dark,
    minWidth: '250px',
    width: '80%'
  },
  inlineTaco: {
    maxWidth: '1.5em',
    margin: `0 2px`
  },
  subSection: {
    fontWeight: 900,
    textTransform: 'uppercase'
  },
  recipientBox: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    minWidth: `calc(100% - ${theme.spacing(2)}px)`,
    backgroundColor: 'rgba(48, 63, 159, .3)'
  },
  cardContainer: {
    height: theme.spacing(80),
    width: '90%',
    overflow: 'auto'
  },
  champCard: {
    width: theme.spacing(40),
    minWidth: theme.spacing(40),
    margin: theme.spacing(2),
    color: theme.palette.primary.dark,
    textAlign: 'center'
  }, 
  champImageContainer: {
    height: '150px'
  },
  comment: {
    marginBottom: '10px'
  },
  champBox: {
    backgroundColor: 'white',
    color: theme.palette.primary.dark,
    width: '60%',
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '2em',
    borderRadius: '4px'
  },
  champImage: {
    maxWidth: '90px',
    borderRadius: '100%'
  },
  fullHeight: {
    height: '100%'
  }
}))

export const MainPage = ({
  user,
  fullUserData,
  tacoTypeaheadSelection, 
  handleChange, 
  isPublicView, 
  usersWithTacosToGive,
  usersWithTacosReceived,
  handleSendTaco, 
  workingTaco, 
  availableUsers, 
  tacoTypeaheadUsers,
  setTacoTypeaheadSelection,
  lastMonthTopTacoChamp
}) => {
  const classes = useStyles()

  return (
    <>
      <CssBaseline />
      <Grid container direction='row' justify={'space-around'} className={classes.container}>
        <Hidden mdDown>
          <Grid item lg={3}>
            <MainRemaining classes={classes} availableUsers={usersWithTacosToGive} />
          </Grid>
        </Hidden>
        {
          !isPublicView && (
            <Grid item lg={4} md={10} sm={10} xs={12}>
              <MainGiving
                {...{
                  classes, 
                  user,
                  fullUserData, 
                  handleSendTaco, 
                  tacoTypeaheadUsers, 
                  workingTaco, 
                  setTacoTypeaheadSelection, 
                  handleChange, 
                  tacoTypeaheadSelection
                }}
              />
            </Grid>
          )
        }
        {
          !isPublicView ? (
            <Grid item lg={4} md={10} sm={10} xs={12}>
              <MainStanding classes={classes} allEmployeeTacoData={usersWithTacosReceived} />
            </Grid> 
          ) : (
            <Grid item lg={8} md={12} sm={12}>
              <MainStandingBeautiful classes={classes} allEmployeeTacoData={usersWithTacosReceived} lastMonthTopTacoChamp={lastMonthTopTacoChamp}/>
            </Grid> 
          )
        }
        {
          user.isAdmin && !isPublicView && (
            <Grid container direction='row' justify={'space-around'}>
              <Grid item xs={11}>
                <Admin 
                  classes={classes}
                  availableUsers={availableUsers}
                />
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </>
  )
}