
import React, { useRef, useEffect } from 'react'
import moment from 'moment'
import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core'
import HorizontalScroll from 'react-scroll-horizontal'
import { PrimaryContainer } from '../../../shared/PrimaryContainer'
import { getAnyAvatar } from '../../../data/avatar'
import { Countdown } from '../../../shared/Countdown'
import { InlineTaco } from '../../../shared/InlineTaco'


const SECTION_TITLE = `${moment().format('MMMM')} TacoChamps`
const COMMENTS_TO_SHOW = 4

export const MainStanding = ({ classes, allEmployeeTacoData }) => (
  <PrimaryContainer mainTitle={SECTION_TITLE} shortTitle={'TacoChamps'}>
    <Grid container>
      {allEmployeeTacoData && allEmployeeTacoData
        .sort((t, pt) => pt.monthlyTacosReceived - t.monthlyTacosReceived)
        .map(tacoHolder => (
          <Box className={classes.recipientBox}>
            <Grid container direction='row'>
              <Grid item xs={6}><Typography variant='button' className={classes.subSection}>{tacoHolder.firstName} {tacoHolder.lastName}</Typography></Grid>
              <Grid item xs={6}><Typography variant='button' className={classes.subSection}>{tacoHolder.monthlyTacosReceived}
                <InlineTaco /> (s) rec’d this month</Typography></Grid>
            </Grid>
            {tacoHolder.commentsReceived.map(comment => (
              <Typography variant='subtitle2' className={classes.comment}><InlineTaco />{comment}</Typography>
            ))}
          </Box>
        ))}
    </Grid>
  </PrimaryContainer>
)

export const MainStandingBeautiful = ({ classes, allEmployeeTacoData, lastMonthTopTacoChamp }) => {
  const firstReceivedComments = (tacoHolder) => tacoHolder.commentsReceived.filter((_, i) => i < COMMENTS_TO_SHOW)
  const remainingCommentCount = (tacoHolder) => tacoHolder.commentsReceived.length - COMMENTS_TO_SHOW
  const tacoChampList = allEmployeeTacoData ? allEmployeeTacoData.filter(ed => ed.monthlyTacosReceived > 0).sort((t, pt) => pt.monthlyTacosReceived - t.monthlyTacosReceived) : []
  const cardsRef = useRef([]);

  useEffect(() => {
    if (tacoChampList.length > 3) {
      let currentRef = 0
      const interval = setInterval(() => {
        cardsRef.current = cardsRef.current.slice(0, tacoChampList.length);
        if (cardsRef.current[currentRef]) cardsRef.current[currentRef].scrollIntoView({ behavior: 'smooth' })
        currentRef = !(currentRef + 1 === tacoChampList.length) ? currentRef + 1 : 0
      }, 10000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [tacoChampList])

  const getTacoChampCards = (tacoChampList) => (
    tacoChampList
    .map((tacoHolder, i) => (
      <Card className={classes.champCard} ref={el => cardsRef.current[i] = el}>
        <CardMedia
          className={classes.champImageContainer}
          title='Champ'
        >
          <Grid container alignItems='center' alignContent='center' justify='center' className={classes.fullHeight}>
            <Grid item>
              <img className={classes.champImage} src={tacoHolder.photoUrl || getAnyAvatar()} alt={`User ${tacoHolder.firstName}}`} />
            </Grid>
          </Grid>
        </CardMedia>
        <CardContent>
          <Box mb={2}>
            <Typography Typography color='textSecondary' align='center' className={classes.subSection}>
              {tacoHolder.firstName} {tacoHolder.lastName}
            </Typography>
            <Typography color='textSecondary' align='center' gutterBottom className={classes.subSection}>
              {tacoHolder.monthlyTotalTacosReceived} <InlineTaco />(s) rec’d this month
            </Typography>
          </Box>
          {firstReceivedComments(tacoHolder).map(comment => (
            <Typography variant='subtitle2' className={classes.comment}><InlineTaco />{comment}</Typography>
          ))}
          {remainingCommentCount(tacoHolder) > 0 && (
            <Typography>... plus {remainingCommentCount(tacoHolder)} more nice thing{remainingCommentCount(tacoHolder) > 1 && 's'}</Typography>
          )}
        </CardContent>
      </Card>
    ))
  )

  return (
    <PrimaryContainer mainTitle={SECTION_TITLE}>
      <Grid container className={classes.cardContainer} direction='row' justify='space-around' alignItems='stretch' wrap='nowrap'>
        {
          !!(tacoChampList) && ((tacoChampList.length > 3) ? (
            <HorizontalScroll>
              {getTacoChampCards(tacoChampList)}
            </HorizontalScroll>
          ) : (
            <>{getTacoChampCards(tacoChampList)}</>
          ))
        }      
      </Grid>
      {
          lastMonthTopTacoChamp && (
            <Grid container direction='column' justify='center' className={classes.champBox}>
              <Grid item><Typography align='center' variant='h5'>Last Month's Taco Champ</Typography></Grid>
              <Grid item>
                <Grid container direction='row' alignItems='center' justify='center'>
                  <Grid item>
                    <img className={classes.profileImgLarge} src={lastMonthTopTacoChamp.photoUrl || getAnyAvatar()} alt={`User ${lastMonthTopTacoChamp.firstName}}`} />
                  </Grid>
                  <Grid item>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography Typography color='textSecondary' align='center' className={classes.subSection}>
                          {lastMonthTopTacoChamp.firstName} {lastMonthTopTacoChamp.lastName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color='textSecondary' align='center' gutterBottom className={classes.subSection}>
                          {lastMonthTopTacoChamp.tacosGifted} <InlineTaco />(s) rec’d !!
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
      }
      <Grid container>
        <Countdown />
      </Grid>
    </PrimaryContainer>
  )
}
